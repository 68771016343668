/** @format */

.head-figure {
  /* max-width: 178px; */
  text-align: center;
  margin: 0px auto;
  padding: 20px 0px;
}
.home-main-header {
  background: #E0D4BD;
  border-bottom: 2px solid #874439;
  text-align: center;
  margin: 0px auto;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-logo-text {
  display: none;
}

.qalara-logo-padding{
  padding-left: 12px;
}

.qrafted-logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.figcaption {
  font: 18px/22px "rlwy-it";
  color: #9d4930;
  display: block;
  margin: 6px auto;
  text-align: center;
}
.content-blk .info-blk {
  background: #fff;
  font-size: 0;
  padding: 20px 30px 40px;
}
.content-blk .info-blk .info-row {
  width: 100%;
}
.content-blk .info-blk .info-lft-blk {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.content-blk .info-blk .info-ryt-blk {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}
.content-blk .info-blk .info-lft-blk h1 {
  font: 28px/32px "ars-bd";
  color: #191919;
  line-height: 130%;
}
.content-blk .info-blk .info-ryt-blk p {
  font: 20px/36px "rlwy-rg";
  color: #4e4848;
  text-align: right;
  line-height: 140%;
}
.content-blk .info-blk .info-lft-blk h2 {
  font: 22px/26px "rlwy-md";
  color: #4e4848;
  padding-top: 30px;
}
.content-blk .info-blk .info-lft-blk p {
  font: 22px/26px "rlwy-rg";
  color: #4e4848;
  padding-top: 5px;
}
.content-blk .info-blk .info-ryt-blk .info-action-blk {
  padding-top: 30px;
  text-align: right;
  font-size: 0;
}
.content-blk .info-blk .info-ryt-blk .info-action-blk p {
  font: 14px/18px "rlwy-rg";
  color: #000;
  padding-top: 8px;
}
.content-blk .info-blk .info-ryt-blk .info-action-blk .actionlike,
.content-blk .info-blk .info-ryt-blk .info-action-blk .share {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}
.content-blk .info-blk .info-ryt-blk .info-action-blk .actionlike a {
  border: 1px solid #9d4930;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
}
.content-blk
  .info-blk
  .info-ryt-blk
  .info-action-blk
  .actionlike.no-fav
  a::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 15px;
  background: url("./images/fav.png") center no-repeat;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.content-blk
  .info-blk
  .info-ryt-blk
  .info-action-blk
  .actionlike.fav
  a::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 15px;
  background: url("./images/fav-fill.png") center no-repeat;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.content-blk .info-blk .info-ryt-blk .info-action-blk .share a {
  border: 1px solid #9d4930;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 2px;
}
.content-blk .info-blk .info-ryt-blk .info-action-blk .share a::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url("./images/share.svg") center no-repeat;
}
.content-blk .description-blk {
  background: #f2f0eb;
  padding: 40px 30px 20px;
}
.content-blk .description-blk aside {
  padding-top: 40px;
}
.content-blk .description-blk aside:first-child {
  padding-top: 0;
}
.content-blk .description-blk aside h3 {
  font: 18px/24px "rlwy-rg";
  color: #4e4848;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}
.content-blk .description-blk aside h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 1px;
  background: #9d4930;
}
.content-blk .description-blk aside p {
  font: 16px/22px "rlwy-rg";
  color: #4e4848;
  padding-top: 10px;
}
.content-blk .description-blk aside p:first-child {
  padding-top: 0;
}
.content-blk .description-blk h6 {
  padding-top: 50px;
  font: 14px/18px "ars-rg";
  color: #4e4848;
  text-align: center;
}
.video-wrapper {
  position: relative;
  width: 100%;
}
.video-wrapper .control-wrapper {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 90%;
  transform: translateX(-50%);
}
.video-wrapper .control-wrapper .seekbar {
  width: 100%;
  position: relative;
  height: 2px;
}
.video-wrapper .control-wrapper .seekbar input[type="range"] {
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  background-color: #fff;
  display: inherit;
}
.video-wrapper
  .control-wrapper
  .seekbar
  input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}
.video-wrapper
  .control-wrapper
  .seekbar
  input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  cursor: pointer;
  background: #9d4930;
  box-shadow: -2000px 0 0 2000px #9d4930;
}
.video-wrapper .control-wrapper .seekbar span {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #9d4930;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.video-wrapper .control-wrapper .other-controls {
  padding: 20px 0;
  position: relative;
}
.video-wrapper .control-wrapper .other-controls .btn {
  width: 18px;
  height: 18px;
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
}
.video-wrapper .control-wrapper .other-controls .btn.play {
  background: url("./images/icon-play.png") center no-repeat;
  background-size: contain;
}
.video-wrapper .control-wrapper .other-controls .btn.pause {
  background: url("./images/icon-pause.png") center no-repeat;
  background-size: contain;
}
.video-wrapper .control-wrapper .other-controls .btn.muteon {
  background: url("./images/icon-mute.png") center no-repeat;
  background-size: contain;
}
.video-wrapper .control-wrapper .other-controls .btn.muteoff {
  background: url("./images/icon-volume.png") center no-repeat;
  background-size: contain;
}
.video-wrapper .control-wrapper .other-controls .btn.fullscreen {
  background: url("./images/icon-screen.png") center no-repeat;
  position: absolute;
  right: 0;
  top: 20px;
  background-size: contain;
}
.video-wrapper .control-wrapper .other-controls .time-track {
  color: #fff;
  display: inline-block;
  padding-left: 30px;
  font: 12px/12px "ars-rg";
  vertical-align: middle;
  letter-spacing: 0.5px;
}
.home-details {
  font-family: "rlwy-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 170%;
  text-align: center;
  color: #000000;
  margin: 20px 65px 70px;
}
.home-banner-section {
  position: relative;
  margin: 0px 140px 70px;
}
.home-banner-content {
  position: absolute;
  top: 45px;
  left: 42%;
  padding: 20px;
  border: 1px solid #d9bb7f;
  border-radius: 10px;
}
.home-banner-content p {
  font-family: "rlwy-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
.home-banner-content span {
  font-family: "ars-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 55px;
  letter-spacing: 0.16em;
  color: #000000;
  margin-left: 20px;
  vertical-align: super;
}
.stories-header {
  font-family: "ars-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #874439;
  text-align: center;
  margin: 0px 20px 10px;
}
.stories-sub-header {
  font-family: "ars-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #874439;
  padding: 20px 15% 30px;
}
.stories-row {
  display: inline-block;
  width: 46%;
  padding: 2%;
}
.stories-button {
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 8px 20px;
  font-family: "ars-rg";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #874439;
  margin-left: auto;
  height: fit-content;
  margin-top: 8px;
}
.stories-section {
  padding: 80px 100px;
  background: #F2F0EB;
}
.footer-section-1 {
  background: #C2B7A2;
  font-family: "rlwy-rg";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #191919;
  padding: 60px 0px;
}
.footer-section-1 a {
  color: #191919;
  font-weight: bold;
}
.footer-section-2 {
  background: #191919;
  font-family: "sen-rg";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #F9F7F2;
  padding: 60px 0px 40px;
}
.home-page-title {
  color: #874439;
}
.story-header {
  font-family: "ars-rg";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #191919;
  margin: 5px 0px 0px;
}
.story-subheader{
  font-family: "ars-rg";
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  color: #191919;
}
.stories-button {
  font-size: 20px;
  line-height: 25px;
  padding: 12px 20px;
}
@media (max-width: 991px) {
  .head-figure {
    max-width: 100%;
  }
  .figcaption {
    display: block;
    padding-top: 10px;
  }
   .home-banner-content-mob {
    padding: 20px;
    border: 1px solid #d9bb7f;
    border-radius: 10px;
    text-align: center;
    margin: 40px 20px 50px;
  }
  .home-banner-content-mob p {
    font-family: "rlwy-rg";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
  }
  .home-banner-content-mob span {
    font-family: "ars-rg";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.16em;
    color: #000000;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .home-banner-content-mob img {
    margin-top: 20px;
  }
  .home-main-header {
    padding: 20px 0px;
  }

  .qalara-logo-padding{
    padding-left: 0px;
  }

  .stories-header {
    font-size: 20px;
    line-height: 25px;
    margin: 0px 27px 30px;
  }
  .story-header {
    font-family: "ars-rg";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #191919;
    margin: 5px 0px 0px;
  }
  .story-subheader{
    font-family: "ars-rg";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #191919;
  }
  .stories-sub-header {
    font-size: 16px;
    line-height: 20px;
    padding: 30px 0px;
  }
  .footer-section-1 {
    font-size: 18px;
    line-height: 21px;
    padding: 50px 18px;
  }
  .footer-section-2 {
    font-size: 12px;
    line-height: 14px;
    padding: 30px 18px;
  }
  .stories-button {
    font-size: 12px;
    line-height: 15px;
    padding: 8px 10px;
  }
  .home-details {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 12px;
  }
  .home-logo-text {
    display:block;
    font-family: "rlwy-rg";
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #9D4930;
    margin-top: 10px;
  }
  .stories-row {
    display: block;
    width: 100%;
    padding:0px;
    margin-bottom: 40px;
  }
  .stories-section {
    padding: 40px 20px 10px;
    background: #F2F0EB;
  }
  .stories-column p {
    width: 72%;
  }
  .figcaption {
    font-size: 14px;
  }
  .content-blk .info-blk {
    padding: 30px 20px;
  }
  .content-blk .info-blk .info-lft-blk h1 {
    font-size: 18px;
    line-height: 130%;
  }
  .content-blk .info-blk .info-lft-blk h2 {
    font-size: 14px;
    line-height: 18px;
    padding-top: 20px;
  }
  .content-blk .info-blk .info-lft-blk p {
    font-size: 14px;
    line-height: 18px;
    padding-top: 5px;
  }
  .content-blk .info-blk .info-ryt-blk p {
    font-size: 12px;
    line-height: 140%;
    text-align: right;
    padding-right: 30px;
  }
  .content-blk .info-blk .info-ryt-blk .info-action-blk {
    padding-top: 10px;
  }
  .content-blk .description-blk {
    padding: 30px 20px 15px;
  }
  .content-blk .description-blk aside p {
    font-size: 14px;
    line-height: 20px;
  }
  .content-blk .description-blk h6 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 40px;
    text-align: center;
  }
  .content-blk .info-blk .info-ryt-blk .info-action-blk p {
    text-align: right;
    padding-right: 0;
  }
}
@media (max-width: 668px) {
  .video-wrapper .control-wrapper .other-controls {
    padding: 15px 0 20px;
  }
  .video-wrapper .control-wrapper .other-controls .btn.fullscreen {
    top: 18px;
  }
}
@media (max-width: 568px) {
  .head-figure {
    /* margin: 10px 25px 15px; */
  }
  .head-figure img {
    max-width: 130px;
  }
  .figcaption {
    padding-top: 5px;
  }
  .content-blk .description-blk aside {
    padding-top: 30px;
  }
  .content-blk .info-blk .info-ryt-blk p {
    text-align: right;
    padding-right: 0px;
  }
  .content-blk .info-blk .info-ryt-blk .info-action-blk p {
    text-align: right;
    padding-right: 0;
  }
}
@media (max-width: 479px) {
  .content-blk .info-blk {
    padding: 10px 20px 20px;
  }
  .content-blk .info-blk .info-lft-blk {
    width: 65%;
  }
  .content-blk .info-blk .info-ryt-blk {
    width: 35%;
  }
  .content-blk .description-blk {
    padding: 20px;
  }
  .video-wrapper .control-wrapper .seekbar {
    height: 0px;
  }
  .video-wrapper .control-wrapper .seekbar input[type="range"] {
    display: inherit;
  }
  .video-wrapper
    .control-wrapper
    .seekbar
    input[type="range"]::-webkit-slider-runnable-track {
    height: 3px;
    -webkit-appearance: none;
    color: #13bba4;
  }
  .video-wrapper
    .control-wrapper
    .seekbar
    input[type="range"]::-webkit-slider-thumb {
    width: 5px;
    height: 5px;
    -webkit-appearance: none;
    cursor: pointer;
    background: #9d4930;
    box-shadow: -2000px 0 0 2000px #9d4930;
  }
  .video-wrapper .control-wrapper .seekbar #thumb::after {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #9d4930;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .video-wrapper .control-wrapper .other-controls .btn {
    padding: 0 10px;
  }
}
@media (max-width: 410px) {
  .content-blk .description-blk h6 {
    font-size: 10px;
    padding-top: 30px;
    text-align: center;
  }
  .video-wrapper .control-wrapper .other-controls {
    padding: 10px 0 13px;
  }
  .video-wrapper .control-wrapper .other-controls .btn.fullscreen {
    top: 13px;
  }
}
.video-wrapper video {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
@media (min-width: 768px) {
  .video-wrapper video {
    height: calc(100vh - 100px);
  }
}
input[type="range"] {
  width: 100%;
}

/*Chrome*/

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #9a905d;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    color: #43e5f7;
  }
}

/** FF*/

input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}

input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

/* IE*/

input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.livemark{
  letter-spacing: 0.12em;
  padding: 0;
  margin: 0;
  color: #382019;
  font-size: 14px;
  font-family: ars-bd;
}

.livemark-desc{
  padding: 0;
  margin-top: 0;
  color: #382019;
  line-height: 20px;
  margin-bottom: 7px;
}

.bold {
  font-weight: bold;
}
.uppercase{
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .home-banner-content-mob img {
    margin-top: 50px;
  }
}