body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

figure, h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-style: normal
}

button, input, select, textarea, .btn-default {
  outline: none !important
}

a, a:focus, a:hover {
  text-decoration: none;
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  -o-transition: all .25s;
  -ms-transition: all .25s;
  transition: all .25s;
  color: #fff
}

  a:focus, button:focus, input:focus {
      outline: 0 none
  }

  a:before, a:after {
      -webkit-transition: all .25s;
      -moz-transition: all .25s;
      -o-transition: all .25s;
      -ms-transition: all .25s;
      transition: all .25s
  }

ol, ul {
  list-style: none;
  padding: 0;
  margin: 0
}

table {
  border-spacing: 0;
  border-collapse: collapse
}

td, th {
  padding: 0
}

label {
  font-weight: 400;
  margin-bottom: 0
}

article:before, aside:before, footer:before, header:before, nav:before, section:before {
  content: " "
}

article:after, aside:after, footer:after, header:after, nav:after, section:after {
  content: " ";
  clear: both
}

.slick-initialized .slick-slide {
  outline: 0 !important
}

.home-container {
  position: relative;
  background: #F9f7f2;
  padding-top: 30px;
}

input::-webkit-input-placeholder {
  line-height: normal !important
}

input, textarea {
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}