@font-face {
    font-family: 'ars-bdit';
    src: url('./fonts/Arsenal-BoldItalic.eot');
    src: url('./fonts/Arsenal-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Arsenal-BoldItalic.woff2') format('woff2'),
        url('./fonts/Arsenal-BoldItalic.woff') format('woff'),
        url('./fonts/Arsenal-BoldItalic.ttf') format('truetype'),
        url('./fonts/Arsenal-BoldItalic.svg#Arsenal-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ars-it';
    src: url('./fonts/Arsenal-Italic.eot');
    src: url('./fonts/Arsenal-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Arsenal-Italic.woff2') format('woff2'),
        url('./fonts/Arsenal-Italic.woff') format('woff'),
        url('./fonts/Arsenal-Italic.ttf') format('truetype'),
        url('./fonts/Arsenal-Italic.svg#Arsenal-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ars-bd';
    src: url('./fonts/Arsenal-Bold.eot');
    src: url('./fonts/Arsenal-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Arsenal-Bold.woff2') format('woff2'),
        url('./fonts/Arsenal-Bold.woff') format('woff'),
        url('./fonts/Arsenal-Bold.ttf') format('truetype'),
        url('./fonts/Arsenal-Bold.svg#Arsenal-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ars-rg';
    src: url('./fonts/Arsenal-Regular.eot');
    src: url('./fonts/Arsenal-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Arsenal-Regular.woff2') format('woff2'),
        url('./fonts/Arsenal-Regular.woff') format('woff'),
        url('./fonts/Arsenal-Regular.ttf') format('truetype'),
        url('./fonts/Arsenal-Regular.svg#Arsenal-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rlwy-md';
    src: url('./fonts/Raleway-Medium.eot');
    src: url('./fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway-Medium.woff2') format('woff2'),
        url('./fonts/Raleway-Medium.woff') format('woff'),
        url('./fonts/Raleway-Medium.ttf') format('truetype'),
        url('./fonts/Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rlwy-it';
    src: url('./fonts/Raleway-Italic.eot');
    src: url('./fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway-Italic.woff2') format('woff2'),
        url('./fonts/Raleway-Italic.woff') format('woff'),
        url('./fonts/Raleway-Italic.ttf') format('truetype'),
        url('./fonts/Raleway-Italic.svg#Raleway-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'rlwy-rg';
    src: url('./fonts/Raleway-Regular.eot');
    src: url('./fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway-Regular.woff2') format('woff2'),
        url('./fonts/Raleway-Regular.woff') format('woff'),
        url('./fonts/Raleway-Regular.ttf') format('truetype'),
        url('./fonts/Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sen-rg';
    src: url('./fonts/sen-regular-webfont.eot');
    src: url('./fonts/sen-regular-webfont.eot') format('embedded-opentype'),
        url('./fonts/sen-regular-webfont.woff') format('woff2'),
        url('./fonts/sen-regular-webfont.woff') format('woff'),
        url('./fonts/sen-regular-webfont.ttf') format('truetype'),
        url('./fonts/sen-regular-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}